(function($, viewport){
    $(document).ready(function(){
            replaceSVG();
            setTimeout(function(){
                halfPageWidth();
            },100);
            hideShowTrackingHistory();
            hideShowFAQ();
            hideShowDetails();

    });

    function hideShowFAQ(){
        faq = $('.row-faq');
        showFAQ = $('#show-faq');
        $('#hide-faq').on('click', function(){
            faq.removeClass('show');
            showFAQ.show();
            return  false;
        });
        
        $('.faq-box-link').bind('click', function(){
            faq.addClass('show');
            showFAQ.hide();
            animateToElement(faq);
        });
        
        showFAQ.on('click', function(){
            faq.addClass('show');
            $(this).hide();
            animateToElement(faq);
            return false;
        });
    }
    
    function hideShowTrackingHistory(){
        var trackingHistory = $('.tracking-history-wrap');
        $('#hide-tracking-history').on('click', function(){
            trackingHistory.removeClass('show');
            $('#show-tracking-history').show();
            return  false;
        });
        
        $('#show-tracking-history').on('click', function(){
            trackingHistory.addClass('show');
            $(this).hide();
            animateToElement(trackingHistory);
            return false;
        });
    }
    
    function hideShowDetails(){
        var productDetails = $('.products-details-wrap');
        var senderAddress = $('.sender-address-wrap');
        $('#hide-details').on('click', function(){
            senderAddress.removeClass('show');
            productDetails.removeClass('show');
            $('#show-details').show();
            return false;
        });
        
        $('#show-details').on('click', function(){
            senderAddress.addClass('show');
            productDetails.addClass('show');
            $(this).hide();
            animateToElement(".sender-address-wrap");
            return false;
        });
    }
    
    function animateToElement(el){
         $('html, body').animate({
            scrollTop: $(el).offset().top
        }, 2000);
    }

    function halfPageWidth(){
        var halfPage = $('.half-page');
        var per =2;
        if( viewport.is('<sm') ) {
            per =1;
        }
        halfPage.width($(window).width()/per);
        $(window).on('resize', function(){
            var per =2;
            if( viewport.is('<sm') ) {
                per =1;
            }
            halfPage.width($(window).width()/per);
        });
    }
    
    //Replace all SVG images with inline SVG
    function replaceSVG() {
        jQuery('img.svg').each(function(){
            var $img = jQuery(this);
            var imgURL = $img.attr('src');
            jQuery.get(imgURL, function(data) {
                    var $svg = jQuery(data).find('svg');
                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');
                    // Replace image with new SVG
                    $img.replaceWith($svg);

            });
        });
    };
})(jQuery, ResponsiveBootstrapToolkit);